<template>
  <q-layout view="hHr LpR lFr" style="min-height: 68px">
    <q-header>
      <HeaderToolbar
        :open-menu-left="menuLeftOpen"
        @toggle-menu-left="menuLeftOpen = !menuLeftOpen"
        @toggle-menu-right="menuRightOpen = !menuRightOpen"
      />
    </q-header>

    <DrawerAccount v-model="menuRightOpen" />
  </q-layout>
</template>

<script>
import DrawerAccount from '@/components/drawer/Account'
import HeaderToolbar from '@/components/header/toolbar/Toolbar.vue'
import { useAuthStore } from '@/stores/auth'
import { useProfilesStore } from '@/stores/profiles'
import { useTypesStore } from '@/stores/types'
import { storage } from '@crdc/utils'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'MainLayout',
  components: {
    HeaderToolbar,
    DrawerAccount,
  },
  setup() {
    const menuLeftOpen = ref(true)
    const menuLeftOpenMax = ref(2)
    const menuRightOpen = ref(false)

    return {
      menuLeftOpen,
      menuLeftOpenMax,
      menuRightOpen,
      toggleRightDrawer() {
        menuRightOpen.value = !menuRightOpen.value
      },
    }
  },
  watch: {
    menuLeftOpen() {
      const count = this.menuLeftOpenMax + 1
      this.menuLeftOpenMax = count % 3

      if (this.menuLeftOpenMax === 0) {
        this.menuLeftOpen = false
      }
      if (this.menuLeftOpenMax === 1) {
        this.menuLeftOpen = true
      }
      if (this.menuLeftOpenMax === 2) {
        this.menuLeftOpen = true
      }
    },
  },
  async preFetch() {
    const useStoreAuth = useAuthStore()
    const useStoreProfile = useProfilesStore()
    const useStoreTypes = useTypesStore()
    useStoreAuth.currentAuthenticatedUser()
    useStoreTypes.fetchAllContractorTypes()
    useStoreTypes.fetchAllAddressTypes()
    useStoreTypes.fetchAllContactTypes()
    useStoreTypes.fetchAllStatusTypes()
    if (useStoreProfile.profile) {
      useStoreProfile.fetchAllProfilesByUser(storage.get('me'))
    }
  },
})
</script>
