import { api } from '@crdc/utils'
import { defineStore } from 'pinia'
import { useNotifyStore } from './notify'

const API_PESSOA = '/pessoa/v1'

export const useUserStore = defineStore('user', {
  state: () => ({
    user: {},
    notifyStore: useNotifyStore(),
  }),
  getters: {
    getUser: (state) => state.user,
  },
  actions: {
    createUser(payload) {
      return api
        .post(`${API_PESSOA}/usuarios`, { ...payload })
        .catch((error) => {
          this.notifyStore.fetchError(error.message)
        })
    },
    updateUser(id, payload) {
      return api
        .put(`${API_PESSOA}/usuarios/atualizarUsuario/${id}`, {
          ...payload,
        })
        .catch((error) => {
          this.notifyStore.fetchError(error.message)
        })
    },
    getUserById(id) {
      return api.get(`${API_PESSOA}/usuarios/${id}`).catch((error) => {
        this.notifyStore.fetchError(error.message)
      })
    },
    getInvitesById(id) {
      return api
        .get(`${API_PESSOA}/usuarios/listarConvites/${id}`)
        .catch((error) => {
          this.notifyStore.fetchError(error.message)
        })
    },
    deleteInvite(id) {
      return api
        .delete(`${API_PESSOA}/usuarios/deletarConvite/${id}`)
        .catch((error) => {
          this.notifyStore.fetchError(error.message)
        })
    },
    resendInvite(id) {
      return api
        .get(`${API_PESSOA}/usuarios/reenviarConvite/${id}`)
        .catch((error) => {
          this.notifyStore.fetchError(error.message)
        })
    },
    sendInvite(payload) {
      return api
        .post(`${API_PESSOA}/usuarios/enviarConvite`, { ...payload })
        .catch((error) => {
          this.notifyStore.fetchError(error.message)
        })
    },
    saveProfileInUse(payload) {
      return api
        .patch(
          `${API_PESSOA}/usuarios/${payload.uuid}/atualizaPerfilLogado/${payload.idPerfilGrupoUsuario}`
        )
        .catch((error) => {
          this.notifyStore.fetchError(error.message)
        })
    },
  },
})
