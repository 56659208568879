import userDefault from '@/assets/user_default.png'
import { api, storage } from '@crdc/utils'
import { Auth } from 'aws-amplify'
import { defineStore } from 'pinia'
import { useNotifyStore } from './notify'

const API_PESSOA = '/pessoa/v1'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    me: {},
    termoPolitica: false,
    notifyStore: useNotifyStore(),
  }),
  actions: {
    async signIn(payload) {
      return await Auth.signIn(payload.username, payload.password)
        .then((data) => {
          storage.set('sub', data.attributes.sub)
          storage.set('jwt', data.signInUserSession?.idToken?.jwtToken)
          const user = this.getUserCurrent(data)
          this.getTermo(data.attributes.sub)
          this.me = user
          storage.set('me', user)
          this.me.profilePicture = userDefault
        })
        .catch((error) => {
          this.notifyStore.fetchErrorNotLogged(error.message)
        })
    },
    signUp(payload) {
      return Auth.signUp({
        username: payload.username,
        password: payload.password,
        attributes: {
          given_name: payload.nome,
          family_name: payload.sobrenome,
          email: payload.email,
          'custom:cpf': payload.cpf,
        },
      })
    },
    getTermo(uuid) {
      api
        .get(`${API_PESSOA}/usuarios/${uuid}/getAceiteTermo`)
        .then((data) => {
          this.termoPolitica = data.data
        })
        .catch((error) => {
          this.notifyStore.fetchError(error.message)
        })
    },
    async signOut() {
      await Auth.signOut({ global: true })
      this.$reset()
      storage.removeAll()
    },
    async forgotPassword(payload) {
      const { username } = payload
      try {
        return await Auth.forgotPassword(username)
      } catch (error) {
        this.notifyStore.fetchError(error.message)
      }
    },
    async forgotPasswordSubmit(payload) {
      const { username, code, password } = payload
      try {
        return await Auth.forgotPasswordSubmit(username, code, password)
      } catch (error) {
        this.notifyStore.fetchError(error.message)
      }
    },
    getUserCurrent(payload) {
      if (payload) {
        return {
          username: payload.username,
          email: payload.attributes.email,
          cpf: payload.attributes['custom:cpf'],
          uuid: payload.attributes.sub,
          firstname: payload.attributes.given_name,
          lastname: payload.attributes.family_name,
          profilePicture: userDefault,
          token: payload.signInUserSession?.idToken?.jwtToken,
          termoPolitica: this.termoPolitica,
        }
      }
      return undefined
    },
    async currentAuthenticatedUser() {
      return await Auth.currentAuthenticatedUser()
        .then((data) => {
          this.me = this.getUserCurrent(data)
          return this.me
        })
        .catch(() => {
          return undefined
        })
    },
    async deleteUser(payload) {
      try {
        const data = await Auth.signIn(payload.username, payload.password)
        data.deleteUser()
      } catch (error) {
        this.notifyStore.fetchError(error.message)
      }
    },
  },
})
