import { api } from '@crdc/utils'
import { defineStore } from 'pinia'
import { useNotifyStore } from './notify'

const API_EMPRESA = '/empresa/v1'
const API_CONTRATANTE = '/contratante/v1'

export const useTypesStore = defineStore('types', {
  state: () => ({
    contractorTypes: [],
    addressTypes: [],
    contactTypes: [],
    statusTypes: [],
    notifyStore: useNotifyStore(),
  }),
  getters: {
    getStatusTypes: (state) => state.statusTypes,
    getContractorTypes: (state) => state.contractorTypes,
    getAddressTypes: (state) => state.addressTypes,
    getContactTypes: (state) => state.contactTypes,
  },
  actions: {
    fetchAllStatusTypes() {
      return api
        .get(`${API_CONTRATANTE}/status`)
        .then(({ data }) => {
          this.statusTypes = data
          return data
        })
        .catch((error) => {
          this.notifyStore.fetchError(error.message)
        })
    },
    fetchAllContractorTypes() {
      return api
        .get(`${API_EMPRESA}/tipos-empresa`)
        .then(({ data }) => {
          this.contractorTypes = data
          return data
        })
        .catch((error) => {
          this.notifyStore.fetchError(error.message)
        })
    },
    fetchAllAddressTypes() {
      return api
        .get(`${API_EMPRESA}/tipos-endereco`)
        .then(({ data }) => {
          this.addressTypes = data
          return data
        })
        .catch((error) => {
          this.notifyStore.fetchError(error.message)
        })
    },
    fetchAllContactTypes() {
      return api
        .get(`${API_EMPRESA}/tipos-contato`)
        .then(({ data }) => {
          this.contactTypes = data
          return data
        })
        .catch((error) => {
          this.notifyStore.fetchError(error.message)
        })
    },
  },
})
